import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const InstructionsScreen = () => {
    const [divHeight, setDivHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
          setDivHeight(window.innerHeight);
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate('/meal1');
    };
    return (
        <div className="outer-container">
            <div className="game-container" style={{
                backgroundImage: `url('/images/instruction-screen-background.jpg')`,
                backgroundPosition: 'bottom',
                height: divHeight,
            }}>
                <img src={`/images/asset-61.png`}
                    style={{
                        maxWidth: '80%',
                        top: '-80px',
                        position: 'relative'
                    }}
                />
                <button className="start-button" onClick={handleSubmit} style={{ position: 'relative', top: '-56px' }}> 

                </button>
            </div>
        </div>
    );
};
export default InstructionsScreen;
