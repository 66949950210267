import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const Layout = ({ children }) => {
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Start the timer when the user visits /meal1
    if (location.pathname === '/meal1') {
      setSeconds(0);
      setMilliseconds(0);
      setTimerRunning(true);
    }

    // Stop the timer when the user reaches /game-end
    if (location.pathname === '/game-end') {
      setTimerRunning(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    let millisecondsIntervalId;

    // Start the timer
    if (timerRunning) {
      millisecondsIntervalId = setInterval(() => {
        setMilliseconds((prevMilliseconds) => {
          if (prevMilliseconds === 9) {
            setSeconds((prevSeconds) => prevSeconds + 1);
            return 0;
          } else {
            return prevMilliseconds + 1;
          }
        });
      }, 100);
    }

    // Stop the timer when the user completes /meal3
    if (location.pathname === '/meal3') {
      // clearInterval(millisecondsIntervalId);
      // setTimerRunning(false);
      // navigate('/game-end');
    }

    return () => {
      clearInterval(millisecondsIntervalId);
    };
  }, [timerRunning, navigate, location.pathname]);

  // Format the timer value into seconds and milliseconds
  const formatTime = () => {
    return `${seconds.toString().padStart(2, '0')}:${milliseconds.toString()}`;
  };

  const stopTimer = () => {
    setTimerRunning(false);
    let time = formatTime();
    updateUserScore(time);
  };


  // Pass stopTimer function down to children
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { stopTimer })
  );

  const updateUserScore = async (score) => {
    // Retrieve userId from localStorage
    const userId = localStorage.getItem('userId');

    // Validate userId and score
    if (!userId || !score) {
      alert('User ID and Score are required');
      return;
    }

    try {
      // Call the API to update user score
      const response = await axios.post('http://165.227.78.116:8090/update-score', {
        id: userId,
        score: score
      });
      // Handle success response here
      console.log(response);
      console.log('User score updated successfully');
    } catch (error) {
      // Handle error here
      console.error('Error:', error);
      // Optionally, display an error message to the user
    }
  };


  return (
    <div>
      <div className="timer-div">
        <div className="timer-box">
          <div style={{
            fontSize: '2rem',
            color: '#834115',
            marginRight: '10px',
            fontWeight: 500,
          }}>Time</div> <div className='timer'> {formatTime()} </div>
          {/* {formatTime()} */}
        </div>
      </div>
      {childrenWithProps}
    </div>
  );
};

export default Layout;
