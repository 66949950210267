import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { gsap } from 'gsap';

const IngredientDraggable = ({ ingredient, onIngredientDrag }) => {
  const [{ difference, isDragging }, drag] = useDrag({
    type: 'INGREDIENT', // Define the type property
    item: { id: ingredient.id },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && dropResult.allowed) {
        onIngredientDrag(ingredient.id);
      }
    },
    collect: (monitor) => {
      return {
        difference: monitor.getDifferenceFromInitialOffset(),
        isDragging: !!monitor.isDragging(),
      }
    },
  });

  return (
    <img
      ref={drag}
      src={ingredient.image}
      alt={ingredient.name}
      className='dragged-item'
      style={{
        width: 'auto',
        height: '72px', // Adjust the height as needed
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        margin: '5px', // Add some margin between ingredients
        ...(isDragging
          ? {
            position: "relative",
            zIndex: 9999,
            transform: `translate(${difference.x}px, ${difference.y}px)`,
            pointerEvents: "none",
          }
          : {})
      }}
      onDragStart={(e) => {
        e.dataTransfer.setData('ingredientId', ingredient.id);
      }}
    />
  );
};

const Meal3Screen = ({ stopTimer }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [divHeight, setDivHeight] = useState(window.innerHeight);
  
  const [validIngredients, setValidIngredients] = useState([6, 3, 7, 2]);

  useEffect(() => {
    function handleResize() {
      setDivHeight(window.innerHeight);
      
    }

    // Ingredient list animations
    gsap.fromTo(".dragged-item",
    { scale: 0 }, 
    { scale: 1, stagger: 0.1, duration: 0.3 });
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    // Remove the value in the id parameter from validIngredients array
    setValidIngredients(prevIngredients => prevIngredients.filter(ingredientId => ingredientId !== parseInt(id)));
  }, [id]);

  const [ingredients, setIngredients] = useState([
    { id: 3, name: 'Chicken', image: '/images/ingredients/ingredient-9.png' },
    { id: 1, name: 'Biscuits', image: '/images/ingredients/ingredient-3.png' },
    { id: 2, name: 'Hodi', image: '/images/ingredients/ingredient-12.png' },
    { id: 5, name: 'Dhal', image: '/images/ingredients/ingredient-8.png' },
    { id: 6, name: 'String Hoppers', image: '/images/ingredients/ingredient-11.png' },
    { id: 7, name: 'Fish', image: '/images/ingredients/ingredient-6.png' },
    { id: 8, name: 'Savory', image: '/images/ingredients/ingredient-13.png' },
    { id: 4, name: 'Aggala', image: '/images/ingredients/ingredient-1.png' },
  ]);

  const [selection, setSelection] = useState([]);

  const handleIngredientDrag = (ingredientId) => {
    if (validIngredients.includes(ingredientId)) {

      setSelection([...selection, ingredientId]);
      setIngredients(ingredients.filter((ingr) => ingr.id !== ingredientId));

    }
    if (selection.length === validIngredients.length) {
      // Navigate to next screen
      console.log('Navigate to next screen');
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'INGREDIENT',
    drop: (item) => {
      handleIngredientDrop(item.id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  // const handleIngredientDrop = (ingredientId) => {
  //     if (validIngredients.includes(ingredientId)) {
  //         // Update selection state using functional form of setSelection
  //         setSelection(prevSelection => [...prevSelection, ingredientId]);
  //         setIngredients(ingredients.filter((ingr) => ingr.id !== ingredientId));
  //         console.log(selection.length);
  //         if (selection.length + 1 === validIngredients.length) {
  //             // Navigate to next screen
  //             // navigate('/game-end');
  //             stopTimer();
  //             console.log('Navigate to next screen');
  //         }
  //     }
  // };
  const handleIngredientDrop = (ingredientId) => {
    // Check if the ingredientId is valid
    if (validIngredients.includes(ingredientId)) {
      // Update selection state using functional form of setSelection
      setSelection(prevSelection => [...prevSelection, ingredientId]);
      setIngredients(ingredients.filter((ingr) => ingr.id !== ingredientId));

      // Check if all valid ingredients are selected except for one of 3 or 7
      if (selection.length + 1 === validIngredients.length) {
        // Navigate to next screen
        stopTimer();
        navigate('/game-end');
        console.log('Navigate to next screen');
        // call the api, send user to the next screen
      }
    }
  };

  return (
    <div className="outer-container">
      <div className="game-container" style={{
        backgroundImage: `url('/images/meal-background.jpg')`,
        backgroundPosition: 'bottom',
        height: divHeight,
      }}>
        <div className="ingredient-box">
          <div style={{}}>
            {ingredients.map((ingredient) => (
              <IngredientDraggable
                key={ingredient.id}
                ingredient={ingredient}
                onIngredientDrag={handleIngredientDrag}
              />
            ))}
          </div>
        </div>
        <div style={{ flex: '0 0 20px' }} /> {/* Gap between ingredient-div and bowl-div */}
        <div style={{ flex: '0 0 auto', display: 'flex', justifyContent: 'center' }}>
          <div
            ref={drop}
            style={{
              width: '200px', // Adjust the width as needed
              height: '120px', // Adjust the height as needed
              // border: '1px solid black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              bottom: 0,
              width: '55%',
              // backgroundColor: isOver ? 'lightgreen' : 'transparent',
              backgroundColor: 'transparent',
            }}
            // Drop target for valid ingredients
            onDragOver={(e) => e.preventDefault()}
          >
            {/* <img className="bowl" src="/images/bowl-1.png" /> */}
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              {/* Ensure the image stays within its parent */}
              <img ref={drop} className="bowl" src="/images/bowl-3.png" style={{
                maxWidth: '100%',
                maxHeight: '100%',
                scale: isOver ? '1.3' : '1',
              }} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Meal3Screen;
