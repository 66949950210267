import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { gsap } from 'gsap';

const GameEndScreen = () => {
    const [isExploding, setIsExploding] = useState(false);
    const [divHeight, setDivHeight] = useState(window.innerHeight);

    useEffect(() => {
        //Window height set
        function handleResize() {
            setDivHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);

        //Animations triggers
        const timeline = gsap.timeline({
            onComplete: animationCompleteCallback,
        });
        // Animation to slide in the image
        timeline.to('.slide-in', {
            duration: 0.5,
            y: -100,
            ease: 'power3.out',
        });
        // Add an onUpdate callback to the timeline
        timeline.eventCallback('onUpdate', checkTime);
        // Function to be called when timeline reaches 0.5 seconds
        function checkTime() {
            const currentTime = timeline.time();
            // Check if the current time is approximately 0.5 seconds
            if (Math.abs(currentTime - 0.25) < 0.025) {
                setIsExploding(true);
            }
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate('/meal1');
    };
    const animationCompleteCallback = () => {
        // show confetti.
        // setIsExploding(true);
        console.log('animation completed!');
    };
    return (
        <div className="outer-container">
            <div className="game-container" style={{
                backgroundImage: `url('/images/final-screen.jpg')`,
                backgroundPosition: 'bottom',
                height: divHeight,
                justifyContent: 'flex-start',
            }}>
                <img src={`/images/congratulations.png`}
                    style={{
                        maxWidth: '80%',
                        position: 'relative',
                        marginTop: '100px'
                    }}
                />
                <h1 className="header" style={{ marginBottom: '64px' }} >You have successfully completed 3 main meals</h1>
                {isExploding && <ConfettiExplosion particleSize={8} />}
                <img className="slide-in" src={`/images/bear.png`} style={{ position: 'absolute', bottom: 0, maxWidth: '45%' }} />
            </div>
        </div>
    );
};
export default GameEndScreen;
