import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import StartScreen from './StartScreen';
import Meal1Screen from './Meal1Screen';
import Meal2Screen from './Meal2Screen';
import Meal3Screen from './Meal3Screen';
import GameEndScreen from './GameEndScreen';
import InstructionsScreen from './InstructionsScreen';
import TestDrag from './TestDrag';
import TestScreen from './TestScreen';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';


import Layout from './Layout';

function App() {
  const [stopTimer, setStopTimer] = useState(() => () => {});
  const handleTimerStop = () => {
    setStopTimer(() => {
      return () => {
        // Logic to stop the timer (setTimerRunning to false)
        // You can access the Layout component's state management here (if needed)
      };
    });
  };
  return (
    <DndProvider backend={TouchBackend}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<StartScreen />} />
            <Route path="/test" element={<TestScreen />} />
            <Route path="/instructions" element={<InstructionsScreen />} />
            {/* <Route path="/meal1" element={<Layout>{(stopTimer) => <Meal1Screen stopTimer={stopTimer} />}</Layout>} />
            <Route path="/meal2" element={<Layout>{(stopTimer) => <Meal2Screen stopTimer={stopTimer} />}</Layout>} />
            <Route path="/meal3" element={<Layout>{(stopTimer) => <Meal3Screen stopTimer={stopTimer} />}</Layout>} /> */}
            <Route path="/meal1" element={<Layout><Meal1Screen /></Layout>} />
            <Route path="/meal2" element={<Layout><Meal2Screen /></Layout>} />
            <Route path="/meal3/:id" element={<Layout><Meal3Screen stopTimer={stopTimer} /></Layout>} />
            <Route path="/game-end" element={<GameEndScreen />} />
          </Routes>
        </Router>
      </div>
    </DndProvider>

  );
}

export default App;
