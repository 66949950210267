import React, { useState, useEffect } from 'react';

const ParentComponent = () => {

    const [divHeight, setDivHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
          setDivHeight(window.innerHeight);
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
    <div style={{
        height: divHeight,
        position: 'relative',
        overflow: 'hidden'
      }}>
      <div style={backgroundImageStyle}></div>
      <div style={childDiv1Style}></div>
      <div style={childDiv2ContainerStyle}>
        <div style={childDiv2Style}></div>
      </div>
    </div>
  );
};

const parentContainerStyle = {
  height: '100vh',
  position: 'relative',
  overflow: 'hidden'
};

const backgroundImageStyle = {
  backgroundImage: 'url("/images/instruction-screen-background.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const childDiv1Style = {
  borderTop: '1px solid black', // Border for top margin
  minHeight: '20vh', // 20% of the screen height
};

const childDiv2ContainerStyle = {
  position: 'absolute',
  top: '50%', // Center vertically
  left: 0,
  right: 0,
  transform: 'translateY(-50%)', // Centering trick
};

const childDiv2Style = {
  width: '200px', // Adjust width as needed
  height: '200px', // Adjust height as needed
  backgroundColor: 'rgba(255, 255, 255, 0.5)', // Example background color
  margin: '0 auto', // Center horizontally
};

export default ParentComponent;
