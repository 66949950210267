import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const StartScreen = () => {
    const [pharmacyName, setPharmacyName] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const imageUrls = [
        '/images/bear.png',
        '/images/congratulations.png',
        '/images/meal-background.jpg',
        '/images/timer-background.png',
        '/images/ingredient-box.png',
        '/images/start-button.png',
        '/images/instruction-screen-background.jpg',
        '/images/final-screen.jpg',
        '/images/bowl-3.png',
        '/images/bowl-2.png',
        '/images/bowl-1.png',
        '/images/asset-61.png',
        '/images/home-frame.png',
        '/images/home-background.jpg',
        '/images/meal-background.png',
        '/images/screen-2-background.png',
        '/images/ingredients/ingredient-10.png',
        '/images/ingredients/ingredient-11.png',
        '/images/ingredients/ingredient-12.png',
        '/images/ingredients/ingredient-6.png',
        '/images/ingredients/ingredient-8.png',
        '/images/ingredients/ingredient-9.png',
        '/images/ingredients/ingredient-1.png',
        '/images/ingredients/ingredient-13.png',
        '/images/ingredients/ingredient-2.png',
        '/images/ingredients/ingredient-3.png',
        '/images/ingredients/ingredient-4.png',
        '/images/ingredients/ingredient-5.png',
    ];
    // Function to preload images
    const preloadImages = () => {
        imageUrls.forEach((imageUrl) => {
            const img = new Image();
            img.src = imageUrl;
        });
    };
    const handleSubmit = async () => {
        // Check if any of the fields are empty or null
        if (!name || !pharmacyName || !phone) {
            alert('Please fill in all fields');
            return;
        }
        // Validate phone length
        if (phone.length !== 10) {
            alert('Please enter a valid 10 digit phone number');
            return;
        }
        // Validate that phone contains only numbers
        if (!/^\d{10}$/.test(phone)) {
            alert('Please enter a valid phone number');
            return;
        }

        console.log(name, pharmacyName, phone);
        try {
            // const response = await axios.post('http://localhost:3010/create-user', {
            const response = await axios.post('http://165.227.78.116:8090/create-user', {
                user: {
                    playerName: name,
                    phramacyName: pharmacyName,
                    contact: phone
                }
            });
            console.log('API Response:', response.data);
            localStorage.setItem('userId', response.data.user.id);
            navigate('/instructions');
        } catch (error) {
            console.error('Error:', error);
            if (error.response.status === 401) {
                alert('You are only allowed to play once!');
            } else {
                alert('An error occurred, please try again!');
            }
        }
    };

    const [divHeight, setDivHeight] = useState(window.innerHeight);

    useEffect(() => {
        preloadImages();
        function handleResize() {
            setDivHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="outer-container">
            <div className="game-container" style={{
                backgroundImage: `url('/images/home-background.jpg')`,
                backgroundPosition: 'bottom',
                height: divHeight,
            }}>
                <div className="start-form">
                    <div className="form-element">
                        <label htmlFor="name">Player Name</label>
                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-element">
                        <label htmlFor="pharmacyName">Pharmacy Name</label>
                        <input
                            type="text"
                            id="pharmacyName"
                            value={pharmacyName}
                            onChange={(e) => setPharmacyName(e.target.value)}
                        />
                    </div>
                    <div className="form-element">
                        <label htmlFor="phone">Contact Number</label>
                        <input type="tel" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>

                    <div className='button-container'>
                        <button className="start-button" onClick={handleSubmit} style={{ marginBottom: '-30px' }}>

                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartScreen;
